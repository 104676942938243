const tabs = {
  2021: true,
}

const mesesArray = [
  { label: 'Enero', num: 1 },
  { label: 'Febrero', num: 2 },
  { label: 'Marzo', num: 3 },
  { label: 'Abril', num: 4 },
  { label: 'Mayo', num: 5 },
  { label: 'Junio', num: 6 },
  { label: 'Julio', num: 7 },
  { label: 'Agosto', num: 8 },
  { label: 'Septiembre', num: 9 },
  { label: 'Octubre', num: 10 },
  { label: 'Noviembre', num: 11 },
  { label: 'Diciembre', num: 12 },
]

const createNewModelFromTab = () => {
  return Object.keys(tabs).reduce((objMonths, yearName) => {
    objMonths[yearName] = mesesArray.reduce((obj, key) => {
      if (!obj[key.label]) {
        obj[key.label] = []
      }
      return obj
    }, {})
    return objMonths
  }, {})
}

const model = createNewModelFromTab()

export const state = () => ({
  tabs,
  model,
})

export const mutations = {
  setInfoToModel(state, { year, month, payload }) {
    state.model[year][month] = payload
  },
  setNewTab(state, payload) {
    state.tabs[payload] = false
    state.model[payload] = mesesArray.reduce((obj, key) => {
      if (!obj[key.label]) {
        obj[key.label] = []
      }
      return obj
    }, {})
  },
  deleteTab(state, payload) {
    delete state.tabs[payload]
    delete state.model[payload]
  },
  setTrueToTab(state, { key, data }) {
    state.tabs[key] = data
  },
  setObjectToModelArray(state, { year, month, payload }) {
    state.model[year][month] = [...state.model[year][month], payload]
  },
  mutateModel(state, { year, month, payload }) {
    state.model[year][month] = payload
  },
  mutateIdModel(state, payload) {
    state.id = payload
  },
}

export const getters = {
  getInfo: (state) => (key) => {
    return state[key]
  },
}

export const actions = {
  async setInfoFromApi({ dispatch, commit, state }) {
    try {
      const model = Object.assign({}, state.model)
      const modelToSend = Object.keys(model).reduce((accObj, year) => {
        const monthsWithEvents = Object.keys(model[year]).reduce(function (
          obj,
          month,
        ) {
          if (model[year][month].length > 0) {
            obj[month] = model[year][month]
          }
          return obj
        },
        {})
        if (!accObj[year]) {
          accObj[year] = monthsWithEvents
        }
        return accObj
      }, {})
      const { data } = await this.$axios.post('visorias/cms', {
        payload: JSON.stringify(modelToSend),
      })
      commit('notifications/addNotification', data.message, { root: true })
      dispatch('getInfoFromApi')
    } catch (error) {
      dispatch('notifications/setErrors', error, { root: true })
    }
  },
  async getInfoFromApi({ commit, dispatch }) {
    try {
      const { data } = await this.$axios.get('visorias/cms', {
        headers: { 'X-API-HASH': process.env.VISORIAS_API_HASH },
      })
      const years = Object.keys(data)
      if (years.length > 0) {
        years.forEach((year) => {
          commit('setNewTab', year)
          data[year].forEach((dataMonth) => {
            const payload = dataMonth.eventos
            commit('mutateModel', {
              year,
              month: dataMonth.mes,
              payload,
            })
          })
        })
        commit('setTrueToTab', { key: years[0], data: true })
      }
    } catch (error) {
      dispatch('notifications/setErrors', error, { root: true })
    }
  },
  async setVisibility({ commit, dispatch }, payload) {
    try {
      commit(
        'notifications/addNotification',
        'Se cambió el estatus de visibilidad con éxito',
        {
          root: true,
        },
      )
      await this.$axios.put('visorias/cms/' + payload.id, payload.data)
    } catch (error) {
      dispatch('notifications/setErrors', error, { root: true })
    }
  },
}
