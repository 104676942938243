
import defaultmixin from '~/mixins/defaultmixin.js'
const LayoutFooter = () => import('~/components/layouts/LayoutFooter.vue')
const LayoutAsideNav = () => import('~/components/layouts/LayoutAsideNav.vue')
const ActionDrop = () => import('~/components/actions/ActionDrop.vue')
const LayoutBreadcrumb = () =>
  import('~/components/layouts/LayoutBreadcrumb.vue')
export default {
  components: {
    LayoutFooter,
    LayoutAsideNav,
    ActionDrop,
    LayoutBreadcrumb,
  },
  mixins: [defaultmixin],
  data() {
    return {
      opts: [
        { label: 'Configuración', url: '/auth/config/', tag: 'nuxt-link' },
        { label: 'Cerrar sesión', action: this.closeSession },
      ],
      show: true,
    }
  },
  computed: {
    menu() {
      return this.$store.getters['catalogs/getInfo']('menu')
    },
  },
  watch: {
    menu: {
      immediate: true,
      handler(obj) {
        if (Object.keys(obj).length === 0) {
          this.$store.dispatch('catalogs/setMenu')
        }
      },
    },
  },
  created() {},
  methods: {
    closeSession() {
      this.$auth.logout()
      this.$toast.success('Tu sesión se ha cerrado exitosamente')
    },
  },
}
