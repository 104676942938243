import { get } from 'lodash'
export default function ({ store, error, route }) {
  const scope = get(store, 'state.auth.user.scope', [])
  // console.log(scope)
  const openModules = ['welcome', 'auth', '']
  const segments = route.path.split('/')
  // console.log(segments)
  const module = segments[1]
  if (!openModules.includes(module)) {
    if (!scope.includes('techtopia')) {
      // console.log(scope)
      // console.log(module)
      // console.log(scope.includes(module))
      if (!scope.includes(module)) {
        return error({
          statusCode: 403,
          message: 'Lo sentimos, acceso no concedido',
        })
      }
    } else {
      return true
    }
  } else {
    return true
  }
}
