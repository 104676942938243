import { apiJsonCall } from '~/helpers/index'

export const state = () => ({
  data: [],
  pagination: {},
  extra: {},
})

export const mutations = {
  addRow(state, payload) {
    state.data = [...state.data, payload]
  },
  setInfo(state, payload) {
    state.data = payload
  },
  setPagination(state, payload) {
    state.pagination = payload
  },
  setExtra(state, payload) {
    state.extra = payload
  },
}

export const getters = {
  getInfo: (state) => (key) => {
    return state[key]
  },
}

export const actions = {
  async setTableFromLaravelApiByGet({ commit, dispatch }, payload) {
    try {
      const { data } = await this.$axios.get(payload.url, payload.opts)
      commit('setInfo', data.data)
      commit('setPagination', {
        currentPage: data.current_page,
        lastPage: data.last_page,
        perPage: data.per_page,
        total: data.total,
      })
    } catch (error) {
      dispatch('notifications/setErrors', error, { root: true })
    }
  },
  async setTableFromLaravelApi({ commit, dispatch }, payload) {
    try {
      const { data } = await this.$axios.post(payload.url, payload.payload)
      commit('setInfo', data.data)
      commit('setPagination', {
        currentPage: data.current_page,
        lastPage: data.last_page,
        perPage: data.per_page,
        total: data.total,
      })
    } catch (error) {
      dispatch('notifications/setErrors', error, { root: true })
    }
  },
  async setTableByApiRest({ commit, dispatch }, payload) {
    try {
      const data = await apiJsonCall(payload)
      commit('setInfo', data)
      commit('setPagination', {
        currentPage: data.current_page,
        lastPage: data.last_page,
        perPage: data.per_page,
        total: data.total,
      })
    } catch (error) {
      dispatch('notifications/setErrors', error, { root: true })
    }
  },
  async setFiltrosTableFromLaravelApiByGet({ commit, dispatch }, payload) {
    try {
      const { data } = await this.$axios.get(payload.url, payload.opts)
      commit('setExtra', data.filtros)
    } catch (error) {
      dispatch('notifications/setErrors', error, { root: true })
    }
  },
}
