import CryptoJS from 'crypto-js'
import axios from 'axios'

const url =
  process.env.NODE_ENV === 'production'
    ? 'https://api.tecthopia.mx/api/'
    : 'http://localhost/api/'

const apiJsonCall = async ({ jsonQuery, entity, namespace, company }) => {
  const queryParsed = JSON.stringify(jsonQuery)
  const apiPayload = JSON.stringify({
    param_js: queryParsed,
    entity,
    namespace,
  })
  const md = CryptoJS.HmacSHA256(apiPayload, process.env.API_SECRET)
  const { data } = await axios.post(
    `${url}${company}/restapi/params`,
    apiPayload,
    {
      headers: {
        'X-API-HASH': md.toString(),
      },
    },
  )
  return data
}

const getPdfFileFromServer = async (urlServer, namePdf = 'file.pdf') => {
  const res = await axios.get(urlServer, { responseType: 'blob' })
  const url = window.URL.createObjectURL(new Blob([res.data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', namePdf)
  document.body.appendChild(link)
  link.click()
}

const getIcon = (event, regexFiles = /(\.jpg|\.jpeg|\.png|\.svg)$/i) => {
  const [file] = event.target.files
  if (!file) {
    return
  }
  const re = regexFiles
  if (!re.exec(file.name)) {
    return false
  }
  return file
}

const getGifFile = (event, regexFiles = /(\.gif)$/i) => {
  const [file] = event.target.files
  if (!file) {
    return
  }
  const re = regexFiles
  if (!re.exec(file.name)) {
    return false
  }
  return file
}

const getImageFile = (event, regexFiles = /(\.jpg|\.jpeg|\.png)$/i) => {
  const [file] = event.target.files
  if (!file) {
    return
  }
  const re = regexFiles
  if (!re.exec(file.name)) {
    return false
  }
  return file
}

const validateModel = (model) => {
  let bool = true
  for (const key in model) {
    if (model[key] === '') {
      bool = false
    }
  }
  return bool
}

const toBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

const meses = [
  { value: 1, label: 'Enero' },
  { value: 2, label: 'Febrero' },
  { value: 3, label: 'Marzo' },
  { value: 4, label: 'Abril' },
  { value: 5, label: 'Mayo' },
  { value: 6, label: 'Junio' },
  { value: 7, label: 'Julio' },
  { value: 8, label: 'Agosto' },
  { value: 9, label: 'Septiembre' },
  { value: 10, label: 'Octubre' },
  { value: 11, label: 'Noviembre' },
  { value: 12, label: 'Diciembre' },
]

// export const fetchFile = (event, regexFiles = /(\.jpg|\.jpeg|\.png)$/i) => {
//   const [file] = event.target.files
//   if (!file) {
//     return
//   }
//   const re = regexFiles
//   if (!re.exec(file.name)) {
//     return null
//   }
//   return file
// }

// const getPpdfFileFromBase64 = async (urlServer, namePdf = 'file.pdf') => {
//   const res = await axios.post(
//     '/tiendachivas/ecommerce/printlabels',
//     { id },
//   )
//   const linkSource = `data:application/pdf;base64,${res.data}`
//   const link = document.createElement('a')
//   link.href = linkSource
//   link.setAttribute('download', 'test.pdf')
//   document.body.appendChild(link)
//   link.click()
// }

export {
  apiJsonCall,
  getPdfFileFromServer,
  getIcon,
  getGifFile,
  getImageFile,
  validateModel,
  toBase64,
  meses,
}
