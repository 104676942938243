export const state = () => ({
  data: [],
  equipos: [
    {
      escudo_score: {},
      escudo_oficial: {},
      division: [
        {
          descripcion: '',
        },
      ],
    },
  ],
  escudos: [
    {
      equipo: {},
    },
  ],
  paquetes: [],
  pids: [],
  pid: {},
  carrusel: [
    {
      videos: [],
    },
  ],
  slides: [
    {
      pid: {},
    },
  ],
  slide: {
    slide: {
      pid: {},
    },
    pids: [],
  },
  pagination: {},
})

export const mutations = {
  setToArray(state, payload) {
    state.data = payload
  },
  mutateData(state, payload) {
    state.data = [...state.data, payload]
  },
  deleteData(state, payload) {
    state.data = state.data.filter((v) => v.id !== payload.id)
  },
  addToObject(state, { key, payload }) {
    state[key] = payload
  },
  // saveData(state, { key, payload }) {
  //   state[key] = payload
  // },
  setPagination(state, payload) {
    state.pagination = payload
  },
}

export const getters = {
  getData: (state) => {
    return state.data
  },
  getInfo: (state) => (key) => {
    return state[key]
  },
}

export const actions = {
  async getFromApi(
    { commit, dispatch },
    { headers = {}, url = '', k = '', paginate = false },
  ) {
    try {
      const { data } = await this.$axios.get(url, headers)
      if (paginate) {
        commit('addToObject', { key: k, payload: data.data })
        commit('setPagination', {
          currentPage: data.current_page,
          lastPage: data.last_page,
          perPage: data.per_page,
          total: data.total,
        })
      } else {
        commit('addToObject', { key: k, payload: data })
      }
    } catch (error) {
      dispatch('notifications/setErrors', error, { root: true })
    }
  },
  async getDataByApi({ commit, dispatch }, { headers, url }) {
    try {
      const { data } = await this.$axios.get(url, headers)
      commit('setToArray', data)
    } catch (error) {
      dispatch('notifications/setErrors', error, { root: true })
    }
  },
  async getDataByApiKey({ commit, dispatch }, { headers, url, k }) {
    try {
      const { data } = await this.$axios.get(url, { headers })
      commit('addToObject', { payload: data, key: k })
    } catch (error) {
      dispatch('notifications/setErrors', error, { root: true })
    }
  },
  async getDataFromApi({ commit, dispatch }, { headers = {}, url, mkey }) {
    try {
      const { data } = await this.$axios.get(url, headers)
      commit('addToObject', { key: mkey, payload: data })
    } catch (error) {
      dispatch('notifications/setErrors', error, { root: true })
    }
  },
  async setVisibility({ commit, dispatch }, { url, payload }) {
    try {
      commit(
        'notifications/addNotification',
        'Se cambió el estatus de visibilidad con éxito',
        {
          root: true,
        },
      )
      await this.$axios.put(url, payload)
    } catch (error) {
      dispatch('notifications/setErrors', error, { root: true })
    }
  },
  async saveDataToApi(
    { commit, dispatch },
    { url, payload, headers, mutate = true },
  ) {
    try {
      const { data } = await this.$axios.post(url, payload, headers ?? {})
      if (mutate) {
        // console.log(data)
        commit('mutateData', data)
      }
      if (data.message) {
        commit('notifications/addNotification', data.message, { root: true })
      } else {
        commit(
          'notifications/addNotification',
          'El elemento se guardó con éxito',
          {
            root: true,
          },
        )
      }
    } catch (error) {
      dispatch('notifications/setErrors', error, { root: true })
    }
  },
  async saveDataToApiKey(
    { commit, dispatch },
    { url, payload, k, headers, mutate = true },
  ) {
    try {
      const { data } = await this.$axios.post(url, payload, headers ?? {})
      if (mutate) {
        // console.log(data)
        commit('addToObject', { payload: data, key: k })
      }
    } catch (error) {
      dispatch('notifications/setErrors', error, { root: true })
    }
  },
  async deleteDataFromApi({ commit, dispatch }, { url, data, headers }) {
    try {
      await this.$axios.delete(url, headers ?? {})
      commit('deleteData', data)
      commit('notifications/addNotification', 'Eliminado con éxito', {
        root: true,
      })
    } catch (error) {
      dispatch('notifications/setErrors', error, { root: true })
    }
  },
  async updateModelDataFromApi(
    { commit, dispatch },
    { url, payload, oldData, headers },
  ) {
    try {
      commit('deleteData', oldData)
      const { data } = await this.$axios.put(url, payload, headers ?? {})
      commit('mutateData', data)
      commit('notifications/addNotification', 'Cambio realizado con éxito', {
        root: true,
      })
    } catch (error) {
      dispatch('notifications/setErrors', error, { root: true })
    }
  },
  async updateDataFromApi(
    { commit, dispatch },
    { url, payload, headers, mutation = 'mutateData', mkey = 'data' },
  ) {
    try {
      const { data } = await this.$axios.put(url, payload, headers ?? {})
      if (mutation) {
        commit(mutation, { payload: data, key: mkey })
      } else {
        commit('mutateData', data)
      }
      commit('notifications/addNotification', 'Actualizado con éxito', {
        root: true,
      })
    } catch (error) {
      dispatch('notifications/setErrors', error, { root: true })
    }
  },
  async updateDataToApi(
    { commit, dispatch },
    { url, payload, type, visor, headers },
  ) {
    try {
      await this.$axios.put(url, payload, headers ?? {})
      if (type === 'eliminar') {
        commit('deleteData', visor)
        commit('notifications/addNotification', 'Eliminado con éxito', {
          root: true,
        })
      } else if (type === 'visible' || type === 'activo') {
        commit(
          'notifications/addNotification',
          'El elemento ya no será visible',
          {
            root: true,
          },
        )
      }
    } catch (error) {
      dispatch('notifications/setErrors', error, { root: true })
    }
  },
}
