import { get } from 'lodash'
export default function ({ $auth, store, route, error, redirect }) {
  if (!$auth.isLogged) {
    return false
  }
  const companies = get(store, 'state.auth.user.company', {})
  const [menu] = Object.keys(store.state.catalogs.menu).filter((key) => {
    const [founded] = store.state.catalogs.menu[key].children.filter(
      (el) => el.url === route.path,
    )
    return founded
  })
  // console.log(menu)
  if (!menu) {
    error({ statusCode: 403, message: 'Forbidden' })
    return false
  }

  if ($auth.hasScope('techtopia')) {
    return
  }
  const childrenMenuArr = route.path
    .split('/')
    .filter((el) => el && el !== menu)
  let includes = true
  if (!companies[menu].includes('admin')) {
    includes = companies[menu].some((companyKey) =>
      childrenMenuArr.every((v) => v === companyKey),
    )
  }

  if (!includes && route.path !== '/' && $auth.hasScope(menu)) {
    error({ statusCode: 403, message: 'Forbidden' })
  }
}
