const getJson = (key) =>
  import(`~/json/${key}.json`).then((data) => data || data.default)

export const state = () => ({
  overall: {},
  graphs: {},
  menu: {},
  products: [],
  categorias: [],
  brands: [],
  orders: [],
  ordenesCountr: {},
  bcfilters: [],
  evaluaciones: [],
  titles: { module: '', option: '' },
  meta: {},
})

export const mutations = {
  setInfo(state, payload) {
    state[payload.key] = payload.data
  },
  setToArray(state, payload) {
    state[payload.key] = [...state[payload.key], payload.data]
  },
  deleteToArray(state, payload) {
    state[payload.key] = state[payload.key].filter(
      (el) => el[payload.compare] !== payload.data,
    )
  },
}

export const getters = {
  getInfo: (state) => (key) => {
    return state[key]
  },
}

export const actions = {
  async setInfoFromApi({ commit, dispatch }, payload) {
    try {
      const { data } = await this.$axios.post(payload.url, payload.data)
      commit('setInfo', { key: payload.key, data })
    } catch (error) {
      dispatch('notifications/setErrors', error, { root: true })
    }
  },
  async setMenu({ commit }) {
    const { data } = await this.$axios.get(`administrator/menu`)
    commit('setInfo', { key: 'menu', data: JSON.parse(data.menu) })
  },
  async setJson({ commit }, payload) {
    const { data } = await getJson(payload)
    commit('setInfo', { key: payload, data })
  },
  async setInfoFromBigCommerce({ commit, dispatch }, payload) {
    try {
      const { data } = await this.$axios.get(
        `tiendachivas/ecommerce/${payload.endpoint}`,
        {
          params: payload.params,
        },
      )
      commit('setInfo', { key: payload.endpoint, data })
    } catch (error) {
      dispatch('notifications/setErrors', error, { root: true })
    }
  },
  setCurrentOption({ commit }, payload) {
    commit('setInfo', { key: 'titles', data: payload })
  },
}
