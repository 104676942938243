
export default {
  props: {
    opts: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  data() {
    return {
      isVisible: false,
    }
  },
  computed: {
    options: {
      get() {
        return this.opts
      },
      set(value) {
        return value
      },
    },
  },
}
