import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      allErrors: 'notifications/getErrors',
      allNotifications: 'notifications/getNotifications',
    }),
  },
  watch: {
    allErrors: {
      immediate: true,
      handler(values) {
        if (values.length > 0) {
          values.forEach((message) => {
            this.$toast.error(message, { duration: 9000 })
          })
          this.$store.commit('notifications/reset', 'errors')
        }
      },
    },
    allNotifications: {
      immediate: true,
      handler(values) {
        if (values.length > 0) {
          values.forEach((message) => {
            this.$toast.info(message, { duration: 9000 })
          })
          this.$store.commit('notifications/reset', 'notifications')
        }
      },
    },
  },
}
