
// const Error404 = () => import('~/components/errors/Error404')
// const Error403 = () => import('~/components/errors/Error403')
// const Error500 = () => import('~/components/errors/Error500')
export default {
  components: {
    // Error404,
    // Error403,
    // Error500,
  },
  layout: 'error',
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    image() {
      let url = ''
      switch (this.error.statusCode) {
        case 404:
          url = '/img/error/404.png'
          break
        case 403:
          url = '/img/error/403.png'
          break
        default:
          url = '/img/error/500.png'
          break
      }
      return url
    },
  },
}
