
import defaultmixin from '~/mixins/defaultmixin.js'
// const LayoutFooter = () => import('~/components/layouts/LayoutFooter.vue')
export default {
  auth: false,
  components: {
    // LayoutFooter,
  },
  mixins: [defaultmixin],
}
