import { get } from 'lodash'
export const state = () => ({
  errors: [],
  notifications: [],
})

export const mutations = {
  addError(state, payload) {
    state.errors = [...state.errors, payload]
  },
  addAllErrors(state, payload) {
    state.errors = payload
  },
  addNotification(state, payload) {
    state.notifications = [...state.notifications, payload]
  },
  reset(state, payload) {
    state[payload] = []
  },
}

export const getters = {
  getErrors: (state) => {
    return state.errors
  },
  getNotifications: (state) => {
    return state.notifications
  },
}

export const actions = {
  setErrors({ commit }, payload) {
    const msj = payload?.response?.data?.message
    if (payload?.response?.data?.errors) {
      const keys = Object.keys(payload.response.data.errors)
      const msjs = keys.map((key) => {
        const m = `${key}: ${get(
          payload.response.data.errors,
          `[${key}][0][0]`,
          false,
        )}`
        if (m) {
          return m
        }
        return `${key}: ${get(payload.response.data.errors, `[${key}][0]`, '')}`
      })
      return commit('addAllErrors', msjs)
    } else if (msj && Array.isArray(msj)) {
      return commit('addAllErrors', msj)
    } else if (payload?.response?.data) {
      const keys = Object.keys(payload.response.data)
      if (keys[0] === 'message') {
        return commit(
          'addError',
          payload.response.data[keys[0]] !== ''
            ? payload.response.data[keys[0]]
            : payload.message,
        )
      }
      const msjs = keys.map((key) => {
        const m = `${key}: ${get(payload.response.data, `[${key}][0]`, false)}`
        if (m) {
          return m
        }
        return `${key}: ${get(payload.response.data, `[${key}][0]`, '')}`
      })
      return commit('addAllErrors', msjs)
    } else {
      commit('addError', msj || payload.message)
    }
  },
}
