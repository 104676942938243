import Vue from 'vue'
import vueCalendar from 'vue2-simple-calendar'

// https://github.com/Trekels/vue2-calendar#readme

const config = {
  locale: 'es',
  fullDayNames: true,
  fullMonthNames: true,
  languages: {
    es: {
      showMore: 'Mostrar más',
      dayNameShort: ['dom', 'lun', 'mar', 'mie', 'jue', 'vie', 'sab'],
      dayNameLong: [
        'domingo',
        'lunes',
        'martes',
        'miércoles',
        'jueves',
        'viernes',
        'sábado',
      ],
      monthNameShort: [
        'ene',
        'feb',
        'mar',
        'abr',
        'may',
        'jun',
        'jul',
        'ago',
        'sep',
        'oct',
        'nov',
        'dic',
      ],
      monthNameLong: [
        'enero',
        'febrero',
        'marzo',
        'abril',
        'mayo',
        'junio',
        'julio',
        'agosto',
        'septiembre',
        'octubre',
        'noviembre',
        'diciembre',
      ],
    },
  },
}

Vue.use(vueCalendar, config)
